<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-alert
      :show="isExistAccount"
      :variant="userAccount.status === 'pending'? 'warning' : userAccount.status === 'active' ? 'success' : 'danger' "
      class="p-1 mb-2"
    >
      <h4 class="alert-heading d-flex justify-content-between">
        <div style="margin:5px 0">
          <span>{{ $t('date') }}</span>
          <span> : </span>
          <span>2024/02/04</span>
        </div>
        <div style="margin:5px 0">
          {{ $t(userAccount.status) }}
        </div>
      </h4>
      <div class="alert-body">
        <p>
          <span>{{ $t('strategy') }}</span>
          <span> : </span>
          <span>{{ userAccount.strategy_name }}</span>
        </p>
        <p>
          <span>{{ $t('fixed_monthly_subscription_fee') }}</span>
          <span> : </span>
          <span>{{ userAccount.monthly_fee }}</span>
        </p>
        <p>
          <span>{{ $t('percentage_participation_traders_profit') }}</span>
          <span> : </span>
          <span>{{ userAccount.profit_percent }}</span>
        </p>
        <p>
          <span>{{ $t('metatrader_account') }}</span>
          <span> : </span>
          <span v-if=" userAccount.account">{{ userAccount.account.account }}</span>
        </p>
      </div>
      <div
        v-if="userAccount.reject_reason"
        class="alert-footer"
      >
        <h3>{{ userAccount.reject_reason }}</h3>
      </div>
    </b-alert>
    <b-card
      :title="$t('create_copytrade_account')"
    >
      <div
        v-if="!canCreateAccount"
        style="
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 9;
          background: #ccc;
          border-radius: 6px;
          opacity: .4;
          cursor: not-allowed;
        "
      />
      <b-row>
        <b-col
          cols="12"
        >
          <b-alert
            show
            :variant="!canCreateAccount ? 'secondary' : 'success'"
            class="p-2"
          >
            <h6>{{ $t('create_copytrade_description') }}</h6>
          </b-alert>
        </b-col>
        <b-col
          cols="12"
          class="mt-2"
        >
          <validation-observer v-slot="{ invalid }">
            <b-form>
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    :label="`${$t('strategy')} (${$t('register-latin-letters')})`"
                    label-for="strategy"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('strategy')"
                      rules="required"
                    >
                      <b-form-input
                        v-model="strategy"
                        name="strategy"
                        :placeholder="$t('strategy')"
                        autocomplete="nope"
                        :disabled="!canCreateAccount"
                        @keypress="AlphaNumeric($event)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    :label="$t('metatrader_account')"
                    label-for="metatrader_account"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('metatrader_account')"
                      rules="required"
                    >
                      <v-select
                        id="metatrader_account"
                        v-model="selectedAccount"
                        :options="accountOptions"
                        :placeholder="$t('metatrader_account')"
                        :disabled="!canCreateAccount"
                      >
                        <template
                          slot="option"
                          slot-scope="option"
                        >
                          <div>
                            <span>{{ option.account }}</span>
                            <span class="mx-1">({{ option.platform }} - {{ option.group }})</span>
                          </div>
                        </template>
                        <template
                          slot="selected-option"
                          slot-scope="option"
                        >
                          <div>
                            <span>{{ option.account }}</span>
                            <span class="mx-1">({{ option.platform }} - {{ option.group }})</span>
                          </div>
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  class="mt-2 mb-1"
                  :class="!canCreateAccount ? '' : 'text-danger'"
                >
                  <span style="font-weight:500"> {{ $t('copytrade_monthly_subscription') }} </span>
                  <span> ( </span>
                  <span>{{ $t('copytrade_monthly_subscription_desc') }}</span>
                  <span> ) </span>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    :label="$t('fixed_monthly_subscription_fee')"
                    label-for="fixed_monthly_subscription_fee"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('fixed_monthly_subscription_fee')"
                      rules="required|numeric"
                    >
                      <b-form-input
                        v-model="fixedMonthlyFee"
                        name="fixed_monthly_subscription_fee"
                        :placeholder="$t('fixed_monthly_subscription_fee')"
                        autocomplete="nope"
                        :disabled="!canCreateAccount"
                        @keypress="onlyNumbers($event)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    :label="$t('percentage_participation_traders_profit')"
                    label-for="percentage_participation_traders_profit"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('percentage_participation_traders_profit')"
                      rules="required|numeric"
                    >
                      <b-form-input
                        v-model="percentageProfit"
                        name="strategy"
                        :placeholder="$t('percentage_participation_traders_profit')"
                        autocomplete="nope"
                        :disabled="!canCreateAccount"
                        @keypress="onlyNumbers($event)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    :label="$t('agreement_type')"
                    label-for="agreement_type"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('agreement_type')"
                      rules="required"
                    >
                      <v-select
                        id="agreement_type"
                        v-model="selectedAgreement"
                        :options="agreementOptions"
                        :placeholder="$t('agreement_type')"
                        label="title"
                        :clearable="false"
                        :disabled="!canCreateAccount"
                        @input="getAgreementContent"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="!!agreementContent"
                  cols="12"
                >
                  <b-form-group
                    :label="$t('agreement_text')"
                    label-for="agreement_text"
                  >
                    <div
                      style="
                        height: 20rem;
                        overflow: scroll;
                        background-color: #ddd;
                        direction: ltr;
                        text-align: justify;
                        padding: 10px;
                      "
                      v-html="agreementContent"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <b-form-checkbox
                      id="isAgree"
                      v-model="isAgree"
                      name="isAgree"
                      :disabled="!canCreateAccount"
                    >
                      {{ $t('isAgreeAgreement') }}
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  class="mt-1"
                >
                  <b-button
                    block
                    variant="primary"
                    :disabled="!isAgree || invalid || !canCreateAccount"
                    @click="createAccount"
                  >
                    <span v-if="!createPending">
                      {{ $t('create_account') }}
                    </span>
                    <span v-else>
                      <b-spinner
                        small
                        type="grow"
                      />
                    </span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BSpinner,
  BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { alphanumeric } from '@/mixins/alphanumeric'
import TradecopyApis from '@/modules/copytrade/service/apis/index'

const tradecopyApis = new TradecopyApis()

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BSpinner,
    BAlert,
  },
  mixins: [alphanumeric],
  data() {
    return {
      show: false,
      required,
      confirmCode: '',
      userAccount: {},
      strategy: null,
      selectedAccount: null,
      accountOptions: [],
      fixedMonthlyFee: '',
      percentageProfit: '',
      agreementOptions: [],
      selectedAgreement: {},
      agreementContent: null,
      isAgree: false,
      createPending: false,
    }
  },
  computed: {
    isExistAccount() {
      return Object.keys(this.userAccount).length > 0
    },
    canCreateAccount() {
      return !Object.keys(this.userAccount).length > 0 || this.userAccount.status === 'reject'
    },
  },
  mounted() {
    this.getUserAccount()
    this.getAgreements()
    this.getAccounts()
  },
  methods: {
    getAgreements() {
      this.show = true
      tradecopyApis.getAgreements().then(res => {
        this.agreementOptions = res.data.results
      }).finally(() => {
        this.show = false
      })
    },
    getAccounts() {
      this.show = true
      tradecopyApis.getAccounts().then(res => {
        this.accountOptions = res.data.results
      }).finally(() => {
        this.show = false
      })
    },
    getUserAccount() {
      this.show = true
      tradecopyApis.getUserAccount()
        .then(res => {
          if (res.data.results) {
            this.userAccount = res.data.results
          }
        })
        .finally(() => {
          this.show = false
        })
    },
    getAgreementContent() {
      tradecopyApis.getAgreementContent(this.selectedAgreement.id).then(res => {
        this.agreementContent = res.data.results
      })
    },
    createAccount() {
      this.createPending = true

      const data = {
        account_id: this.selectedAccount.id,
        strategy_name: this.strategy,
        monthly_fee: this.fixedMonthlyFee,
        profit_percent: this.percentageProfit,
        agreement_id: this.selectedAgreement.id,
      }

      tradecopyApis.register(data).then(res => {
        this.$swal({
          title: res.data.message,
          icon: 'success',
          timer: 2500,
          showConfirmButton: false,
        })
        this.getUserAccount()
      }).finally(() => {
        this.createPending = false
      })
    },
  },
}
</script>
